import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

const PrivateRoute = ({ children }) => {
  const { logged } = useContext(AuthContext)
  return logged ? children : <Navigate to="/public/login" />
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export { PrivateRoute }
