import { createTheme } from '@mui/material/styles'
import { esES } from '@mui/x-data-grid'
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#19d247',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: ['system-ui', 'Open Sans'].join(','),
    },
  },
  esES,
)

export default theme
